//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mixins from '@/mixins'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  mixins: [mixins],

  data () {
    return {
      relatedPartner: {
        isLoading: true,
        perPage: 1,
        page: 1,
        totalPage: 0,
        data: []
      }
    }
  },

  computed: {
    ...mapState('User/address', ['location']),
    ...mapState('Brand', ['bestSellingBrandAffiliate']),
    isPartnerHasLoadMore () {
      if (this.isAffiliate) {
        return this.bestSellingBrandAffiliate.pagination.total > 1
      } else {
        return this.relatedPartner.page <= this.relatedPartner.totalPage
      }
    },
    partnerData () {
      return this.isAffiliate ? this.bestSellingBrandAffiliate : this.relatedPartner
    }
  },

  watch: {
    location (newVal, oldVal) {
      if (newVal.cityId !== oldVal.cityId || !oldVal.cityId) {
        return
      }

      this.handleResetPartner()

      this.relatedPartner.data = []
      this.relatedPartner.page = 1
      this.relatedPartner.totalPage = 0

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }

      this.getSearchedPartners()
    },

    '$route.query' (newVal, oldVal) {
      if (newVal.search === oldVal.search) {
        return
      }

      if (this.isAffiliate) {
        this.resetBestSellingBrandAffiliate()
      } else {
        this.relatedPartner.data = []
        this.relatedPartner.page = 1
        this.relatedPartner.totalPage = 0
      }

      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }

      this.getSearched()
    }
  },

  mounted () {
    this.resetBestSellingBrandAffiliate()
    this.getSearched()
  },

  methods: {
    ...mapActions('Partner', ['getPartners']),
    ...mapActions('Brand', ['getBestSellingBrandAffiliate']),
    ...mapMutations('Brand', ['resetBestSellingBrandAffiliate']),

    handleShowAll () {
      let url = `/products/all?search=${this.$route.query.search}&show_all=true`

      if (this.isAffiliate) {
        url = `/affiliate/products/${this.$route.params.section}?search=${this.$route.query.search}&show_all_partner=true`
      }

      this.$router.push(url)
    },
    getSearched () {
      if (this.isAffiliate) {
        this.handleTopBrandAffiliate()
      } else {
        this.getSearchedPartners()
      }
    },
    async getSearchedPartners () {
      this.relatedPartner.isLoading = true

      const cityId = localStorage.getItem('location')
        ? JSON.parse(localStorage.getItem('location')).city_id
        : 155

      try {
        const resp = await this.getPartners({
          data: {
            per_page: this.relatedPartner.perPage,
            city_id: cityId,
            page: this.relatedPartner.page,
            search: this.$route.query.search
          },
          setValue: false
        })

        if (resp) {
          this.relatedPartner.data = this.relatedPartner.data.concat(resp.data.data.stores.data)
          this.relatedPartner.totalPage = resp.data.data.stores.last_page
          this.relatedPartner.page++
        }
      } catch (err) {
        this.$message.error('Gagal mendapatkan data partner')
      } finally {
        this.relatedPartner.isLoading = false
      }
    },
    async handleTopBrandAffiliate () {
      this.loading = true
      try {
        const payload = {
          params: {
            paginated: true,
            per_page: 1,
            page: this.bestSellingBrandAffiliate.pagination.nextPage || 1,
            search: this.$route.query.search || ''
          }
        }

        await this.getBestSellingBrandAffiliate(payload)
      } catch (error) {
        const errorMessage = error.response ? error.response.data.message : error
        this.$message.error(errorMessage)
      } finally {
        this.relatedPartner.isLoading = false
      }
    }
  }
}
