//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'

export default {
  async asyncData ({ redirect, store, params, app, error }) {
    let partner = {}

    try {
      const resp = await store.dispatch(
        'Partner/showPartner',
        params.id +
          (!app.$auth.loggedIn
            ? '?session_id=' + app.$cookies.get('session_id')
            : '')
      )

      partner = resp.data.data.store
    } catch (err) {
      if (err.response && err.response.status === 404) {
        error({
          statusCode: 404,
          image: require('@/static/img/error/404.svg'),
          message: 'Partner Tidak Ditemukan',
          subMessage: 'Maaf Partner yang dimaksud tidak ada. Mohon untuk kembali ke beranda',
          type: 'partner-not-found'
        })
      } else {
        error({
          statusCode: err.response ? err.response.status : 500
        })
      }
    }

    return {
      partner
    }
  },

  data () {
    return {
      eventSale: {}
    }
  },
  computed: {
    coverImage () {
      if (this.partner.files) {
        return this.partner.files.length
          ? this.partner.files.filter((file) => {
            return file.name === 'cover_image' && file.variant === 'original'
          })
          : []
      }

      return []
    },

    partnerSEOTitle () {
      let partnerSEOTitle = ''

      if (this.partner) {
        partnerSEOTitle = `${this.partner.name} - Temukan Produk Terbaik di Toko Kami | Paninti Store`
      }

      return partnerSEOTitle
    },

    partnerSEODescription () {
      let partnerSEODescription = ''

      if (this.partner) {
        if (
          this.partner.description === null ||
          this.partner.description === undefined ||
          (this.partner.description instanceof String && this.partner.description.trim() === '')
        ) {
          partnerSEODescription = `Temukan produk terbaik dan terbaru di toko ${this.partner.name}. Dapatkan harga terbaik dan pengalaman belanja online yang nyaman dan aman. Jelajahi katalog lengkap kami dan temukan barang impianmu hari ini.`
        } else {
          partnerSEODescription = this.partner.description
        }
      }

      return partnerSEODescription
    },

    partnerSEOLogoImage () {
      let urlPartnerSEOLogoImg = process.env.URL + '/default-og-image.jpg'

      if (this.partner) {
        const partnerLogoFile = this.partner.files.filter((file) => {
          return file.name === 'logo'
        })

        if (partnerLogoFile.length > 0) {
          const partnerLogoFileThumbnail = partnerLogoFile.filter((file) => {
            return file.variant === 'thumbnail'
          })

          if (partnerLogoFileThumbnail.length > 0) {
            urlPartnerSEOLogoImg = partnerLogoFileThumbnail[0].value
          } else {
            urlPartnerSEOLogoImg = partnerLogoFile[0].value
          }
        }
      }

      return urlPartnerSEOLogoImg
    }
  },

  methods: {
    ...mapActions('Partner', ['showPartner'])
  },

  head () {
    return {
      title: this.partnerSEOTitle,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: this.partnerSEOTitle
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: this.partnerSEOTitle
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: this.partnerSEODescription
        },
        {
          hid: 'description',
          name: 'description',
          content: this.partnerSEODescription
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: this.partnerSEOLogoImage
        }
      ]
    }
  }
}
