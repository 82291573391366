//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import panstoreMixin from '@/mixins'
import VClamp from 'vue-clamp'
import { mapMutations, mapActions } from 'vuex'
export default {
  components: {
    VClamp
  },
  mixins: [panstoreMixin],

  props: {
    item: {
      type: Object,
      default: () => {}
    },
    selectedProducts: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      buttonText: '+ Affiliasi',
      checkboxValue: false
    }
  },
  computed: {
    checked () {
      return this.selectedProducts.includes(this.item.id)
    },
    firstPrice () {
      return this.item.discounted_price.split('-')[0];
    }
  },
  methods: {
    ...mapActions('Product', ['addProductAffiliate']),
    ...mapMutations('Product', ['setShareUrl']),
    handleSetShareUrl (url) {
      this.setShareUrl(url)
    },
    handleGotoDetail (slug) {
      this.$router.push(`/product/${slug}`)
    },
    handleButtonClick () {
      this.$emit('join-affiliate', {
        id: this.item.id,
        checked: !this.checked
      });
    },
    handleCheckboxChange (checked) {
      this.handleButtonClick();
      if (checked) {
        this.buttonText = 'Ditambahkan';
      } else {
        this.buttonText = '+ Affiliate';
      }
    }
  }
}
